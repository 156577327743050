import { createSelector } from 'reselect';
import { getFeatureFlags } from 'utils/feature-flags';
import isAmendingOrderSelector from 'redux/modules/trolley/selectors/amending-order';
import { getCustomerSlotDate } from 'redux/modules/customer-slot/selectors';
import urls from 'constants/urls';
import { dayjs } from 'utils/date';

export const isChristmasHubSelector = createSelector(
  [isAmendingOrderSelector, getCustomerSlotDate, (_state, pathname) => pathname],
  (amendingOrder = false, slotDate, pathname = '') => {
    const { EoS_ChristmasHub: christmasHubFlag, EoS_ChristmasHub_ForceValidDate: forceValidDate } =
      getFeatureFlags();
    const isChristmasHubUrl = pathname.toLowerCase() === urls.groceriesChristmas;
    const formattedSlotDate = dayjs(forceValidDate ? '2024-12-22' : slotDate).tz();
    const amendStartDate = dayjs('2024-12-20').tz();
    const amendEndDate = dayjs('2024-12-24').tz();
    const dateIsValid =
      formattedSlotDate.isAfter(amendStartDate) && formattedSlotDate.isBefore(amendEndDate);

    return isChristmasHubUrl && christmasHubFlag && amendingOrder && dateIsValid;
  },
);
