import { createSelector } from 'reselect';
import { getSlotStartTime, getOrderTotal } from 'redux/modules/orders/selectors/get-order';
import { getCustomerOrders } from 'redux/modules/entities/selectors/customer-orders';
import { getStateAtNamespaceKey } from 'redux/get-state-at-namespace-key';

export const getOrders = (state: WtrState) => getStateAtNamespaceKey(state, 'orders');

const completedOrders = createSelector(getOrders, orders => orders?.completedOrders);
const pendingOrders = createSelector(getOrders, orders => orders?.pendingOrders);
const previousOrders = createSelector(getOrders, orders => orders?.previousOrders);
const recentOrders = createSelector(getOrders, orders => orders?.recentOrders);

export const getPendingOrderIds = createSelector(pendingOrders, ({ orderIds = [] }) => orderIds);

export const getPreviousOrderIds = createSelector(previousOrders, ({ orderIds = [] }) => orderIds);

export const getRecentOrderIds = createSelector([recentOrders], ({ orderIds = [] }) => orderIds);

export const getCompletedOrderIds = createSelector(
  completedOrders,
  ({ orderIds = [] }) => orderIds,
);

export const hasOrders = createSelector(
  [getPendingOrderIds, getPreviousOrderIds],
  (pendingOrderIds, previousOrderIds) => pendingOrderIds.length > 0 || previousOrderIds.length > 0,
);

export const hasRecentOrders = createSelector(
  [getRecentOrderIds],
  recentOrderIds => recentOrderIds.length > 0,
);

export const getRecentOrders = createSelector(
  [getRecentOrderIds, getCustomerOrders, (_state, customerOrderId) => customerOrderId],
  (recentOrderIds, customerOrders, customerOrderId) =>
    recentOrderIds.map(orderId => ({
      date: getSlotStartTime({ entities: { customerOrders } }, orderId),
      orderId,
      total: getOrderTotal({ entities: { customerOrders } }, orderId),
      selected: orderId === customerOrderId,
    })),
);

export const hasMoreThanOneRecentOrder = createSelector(
  getRecentOrderIds,
  recentOrderIds => recentOrderIds.length > 1,
);

export const isCurrentOrderInRecentOrders = createSelector(
  [getRecentOrderIds, (_state, customerOrderId) => customerOrderId],
  (recentOrderIds, customerOrderId) => recentOrderIds.some(id => id === customerOrderId),
);

export const getFirstPendingOrderId = createSelector(
  [getPendingOrderIds],
  (orders = []) => orders[0] || null,
);

export const getFirstPreviousOrderId = createSelector(
  [getCompletedOrderIds],
  (orders = []) => orders[0] || null,
);

export const getFirstRecentOrderId = createSelector(
  [getRecentOrderIds],
  (orders = []) => orders[0] || null,
);

export const isPendingOrdersLoading = createSelector(pendingOrders, ({ loading }) => loading);

export const isPendingOrdersLoaded = createSelector(pendingOrders, ({ loaded }) => loaded);
export const getPendingOrdersLastFetchedFor = createSelector(
  pendingOrders,
  ({ lastFetchedFor }) => lastFetchedFor,
);

export const isPreviousOrdersLoading = createSelector(previousOrders, ({ loading }) => loading);
export const isPreviousOrdersLoaded = createSelector(previousOrders, ({ loaded }) => loaded);

export const isRecentOrdersLoading = createSelector(recentOrders, ({ loading }) => loading);

export const hasMorePendingOrders = createSelector(pendingOrders, ({ start }) => !!start);
export const getPendingOrdersNextPageStartIndex = createSelector(
  pendingOrders,
  ({ start }) => start,
);

export const hasMorePreviousOrders = createSelector(previousOrders, ({ start }) => !!start);
export const getPreviousOrdersNextPageStartIndex = createSelector(
  previousOrders,
  ({ start }) => start,
);

export const isMyOrdersPageLoaded = createSelector(
  [isPendingOrdersLoaded, isPreviousOrdersLoaded, getPendingOrdersLastFetchedFor],
  (pendingOrdersLoaded, previousOrdersLoaded, pendingOrdersLastFetchedFor) => {
    return (
      pendingOrdersLastFetchedFor === 'MY_ORDERS' && pendingOrdersLoaded && previousOrdersLoaded
    );
  },
);

export const getFirstPendingOrder = createSelector(
  [getCustomerOrders, getFirstPendingOrderId],
  (customerOrders, firstPendingOrderId) =>
    customerOrders && firstPendingOrderId ? customerOrders[firstPendingOrderId] : undefined,
);

export const isFirstPendingOrderLoaded = createSelector(
  [isPendingOrdersLoaded, getFirstPendingOrder],
  (pendingOrdersLoaded, firstPendingOrder) => {
    return pendingOrdersLoaded && !!firstPendingOrder;
  },
);
